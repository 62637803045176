import axios from "axios";

const AxiosInstance = axios.create({
  //   baseURL: "https://localhost:16042",
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
  "Content-type": "application/x-www-form-urlencoded",
});

export const setToken = (token) => {
  localStorage.removeItem("TOKEN");
  AxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  localStorage.setItem("TOKEN", token);
};

AxiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("TOKEN");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    console.log("[AXIOS-REQUEST]: ", config);
    return config;
  },
  (error) => {
    console.log("[AXIOS-REQUEST-ERROR]: ", error);

    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  (response) => {
    console.log("[AXIOS-RESPONSE]: ", response.data);
    return response.data;
  },
  (error) => {
    console.log(error.status);
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("TOKEN");
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
