import React from "react";
import "./App.css";
import styles from "./styles/modules/app.module.scss";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import MultiStepForm from "./pages/MultiStepForm";
import Verification from "./pages/Verification";
import ErrorDialog from "./components/Error";
import Home from "./pages/Home";

function App() {
  return (
    <React.Fragment>
      <AppHeader />
      <div className="container">
        <div className={styles.app__wrapper}>
          {/* <Paper  elevation={2}> */}
          {/* <Typography variant="h4" align="center">
            {" "}
            Tricor{" "}
          </Typography> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:token" element={<Home />} />
            <Route path="/profiles" element={<LandingPage />} />
            <Route path="/update" element={<MultiStepForm />} />
            <Route path="/error" element={<ErrorDialog />} />
            {/* <Route path="/verify" element={<Verification />} /> */}
            {/* <Route path="/profiles/:token" element={<LandingPage />} /> */}
          </Routes>
          {/* </Paper> */}
        </div>
      </div>
      <AppFooter />
    </React.Fragment>
  );
}

export default App;
