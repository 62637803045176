import React, { useState,useRef,useEffect } from "react";
import Button from "@mui/material/Button";
import { FormControl, FormControlLabel, Typography, FormGroup,FormHelperText } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { Form, Formik } from "formik";
import PreferenceForm from "../pages/PreferenceForm";
import PersonalDetailForm from "./PersonalDetailForm";
import PreviewSubmit from "./PreviewSubmit";
import SuccessDialog from "../components/SuccessDialog";
import styles from "../styles/modules/app.module.scss";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import axios from "../api";
import PageTitle from "../components/PageTitle";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import FileDownloadDoneRoundedIcon from "@mui/icons-material/FileDownloadDoneRounded";
import ErrorDialog from "../components/Error";



const MultiStepForm = () => {
  const steps = [PersonalDetailForm, PreferenceForm, PreviewSubmit];
  const [activeStep, setActiveStep] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const CurrentStep = steps[activeStep];
  const { validationSchema } = CurrentStep;
  const [submissionRefNum, setSubmissionRefNum] = useState("");
  
  const [errorMessage, setErrorMessage] = useState("");
  const [errorType, setErrorType] = useState("");

  // const initialValues = steps.reduce(
  //   (values, { initialValues: initValues }) => (
  //     {
  //     ...values,
  //     ...initValues,
      
  //   }),
  //   {}
  // );

  const initialValues = {

    phoneNumber: "",
    email: "",
    email2: "",
    receiveMethod: "",
    haveCopy: false,
    printLang: false,
    selectLang: "PrintedEng"
  }

  const isLastStep = () => activeStep === steps.length - 1;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validate = (values) => {
    let errors = {};
    const emailRegExp =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      

    const emailRegExp3 =
    /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

    const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    if (values.email) {
      if (!emailRegExp3.test(values.email)) {
        errors.email = "Invalid email address format 電子郵件地址格式不正確";
      }       
      else if (!values.email2) {
        errors.email2 = "Please fill out Email Address (Re-type) 請填寫 電子郵件地址 (再次輸入) "
      }
      
      
    }  

    if (values.email2) {
      
      if (!values.email) {
        errors.email = "Please fill out Email Address 請填寫 電子郵件地址"
      } else if (values.email !== values.email2) {
        errors.email2 = "Email address does not match 電子郵件地址不一致";
      } 
      // else if (!emailRegExp3.test(values.email2)) {
      //   errors.email2 = "Invalid email address format 電子郵件地址格式不正確";
        
      // }
    }  

    if (values.phoneNumber) {
      if (!phoneRegExp.test(values.phoneNumber)) {
        errors.phoneNumber = "Invalid phone number format 聯絡電話號碼格式不正確";
      }
        else if (values.phoneNumber.length < 8 ) {
        errors.phoneNumber = "Invalid phone number format 聯絡電話號碼格式不正確";
      }
    }  

    
    return errors;
  };

  const submitForm = async (values) => {
    console.log(values);
    console.log("submitform");
    
    var input = {
      shareholderEmail: values.email,
      shareholderCommunicationMethod: values.receiveMethod,
      shareholderTelNo: values.phoneNumber,
    };

    axios
      .post(`/instructions/`, input)
      .then((response) => {
        if (response.data) {
          if(response.success)
          {setShowSuccess(true);
          console.log(response.data?.submissionRefNum);
          setSubmissionRefNum(response.data?.submissionRefNum);
          localStorage.removeItem("TOKEN");}
          else
          {     
            setErrorType("submit");
            setErrorMessage(response.messages[0].messageEng);
            setSubmissionRefNum(response.data?.submissionRefNum);
          }
        }
      })
      .catch((error) => {
        setErrorType("unknown");
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          setErrorMessage(error.response.data);
        } else if (error.request) {
          console.log(error.request);
          setErrorMessage(error.request);
        } else {
          console.log("Error", error.message);
          setErrorMessage(error.message);
        }
        console.log(error.config);
        setErrorMessage(error.message);
      });

    await new Promise((resolve) => setTimeout(resolve, 1000));
  };

  const handleSubmit = async (values) =>
    isLastStep() ? submitForm(values) : handleNext();

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,    
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <AssignmentRoundedIcon />,
      2: <InfoRoundedIcon />,
      3: <FileDownloadDoneRoundedIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  return (
    <>
      {" "}
      <PageTitle />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={false}
        validate={validate}
        
      >
        {({ values,errors,touched }) => (
          <>
            {showSuccess ? (
              <SuccessDialog submissionRefNum={submissionRefNum} />
            ) : (
              <></>
              
            )}
            <Stack sx={{ width: "100%" }} spacing={4}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps.map((title) => (
                  <Step key={title}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {title}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
            {/* <Stepper
              activeStep={activeStep}
              className={styles.stepper}
              alternativeLabel
            >
              {steps.map(({ title }) => (
                <Step key={title}>
                  <StepLabel>{title}</StepLabel>
                </Step>
              ))}
            </Stepper> */}
            <CssBaseline />{activeStep===1 &&
            <Typography  variant="h4" align="left">
            I/We would like to receive the future Actionable Corporate Communications and Corporate Communications in the manner as indicated below:
            <br/>
            本人╱我們希望以下列方式收取日後的可供採取行動的公司通訊及公司通訊
          </Typography>}
            <Container maxWidth="sm">
              
              <Box  >
                <Form>
                  <CurrentStep values={values} />
                  

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      variant="contained"
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back 返回
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />

                    <Button type="submit" color="primary" variant="contained">
                      {isLastStep() ? "Submit 提交" : "Continue 繼續"}
                    </Button>
                  </Box>
                </Form>
              </Box>
            </Container>
            {errorMessage ? (
          <ErrorDialog errorMessage={errorMessage} type={errorType} submissionRefNum={submissionRefNum} />
        ) : (
          <>
            
          </>
        )}
          </>
        )}
      </Formik>
      
    </>
  );
};

export default MultiStepForm;
