import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import error from "../error.jpg";
import Box from "@mui/material/Box";

function ErrorDialog(props) {
  const [open, setOpen] = useState(true);
  //const [errorType, setErrorType] = useState("");
  //setErrorType(props.type);
  const handleClose = () => {
    setOpen(false);

    window.location = "https://www.tricoris.com/";
  };

  // useEffect(() => {
  //   if (props.errorMessage) {setOpen(true);console.log("Hi")}
  // }, [props.errorMessage]);
  return (
    <div>
      {/* <Container maxWidth="sm">
        <Box sx={{ bgcolor: "#fff", height: "60vh" }}>
          <Typography variant="h2" align="center">
            {"Error Page"}
            <br /> <br />
          </Typography>{" "}
          <Typography variant="h4" color={"red"} align="center">
            {props.errorMessage}
          </Typography>
        </Box>
      </Container> */}
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle id="success-dialog"> </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.type === "token" && (
              <>
                <Typography variant="h2" align="center">
                  {"Error 錯誤"}
                </Typography>
                <br />
                <Typography variant="h4" color={"red"} align="center">
                  Invalid / Missing QR Code
                  <br />
                  不正確 / 缺少 二維碼
                </Typography>
              </>
            )}
            {props.type === "verify" && (
              <>
                {" "}
                <Typography variant="h2" align="center">
                  {"Error 錯誤"}
                  <br />
                </Typography>{" "}
                <Typography variant="h4" color={"red"} align="center">
                  Verification failed
                </Typography>
              </>
            )}
            {props.type === "submit" && (
              <>
                <Box sx={{ textAlign: "center" }}>
                  <img
                    color="white"
                    height={80}
                    width={80}
                    src={error}
                    alt="Error"
                  />
                </Box>
                <Typography variant="h4" align="center" fontWeight={"bold"}>
                  {" "}
                  SORRY
                  <br />
                  We are unable to process your request.
                  <br />
                  We are here to help, feel free to contact us.
                  <br />
                  抱歉
                  <br />
                  我們無法處理閣下的請求。
                  <br />
                  我們隨時為你提供協助，歡迎聯絡我們。
                  <br />
                </Typography>
                <br />
                <Typography variant="h6" align="center">
                  {" "}
                  If you wish to submit your request again, please scan the QR
                  code to restart the process.
                  <br />
                  若閣下希望再次遞交請求，請掃瞄二維碼以重啟流程。
                  <br />
                  <br />
                  After you click on the OK button, you will be redirected to
                  our Investor Services Centre website.
                  <br />
                  點擊「確定」按鈕後，您將被重新導向到我們的投資者服務中心網站。
                  <br />
                  <br />
                  Reference Number 參考編號 : {props?.submissionRefNum}.
                  <br />
                </Typography>
              </>
            )}
            {props.type === "unknown" && (
              <>
                <Box sx={{ textAlign: "center" }}>
                  <img
                    color="white"
                    height={80}
                    width={80}
                    src={error}
                    alt="Error"
                  />
                </Box>
                <Typography variant="h4" align="center" fontWeight={"bold"}>
                  {" "}
                  SORRY
                  <br />
                  We are unable to process your request.
                  <br />
                  We are here to help, feel free to contact us.
                  <br />
                  抱歉
                  <br />
                  我們無法處理閣下的請求。
                  <br />
                  我們隨時為你提供協助，歡迎聯絡我們。
                  <br />
                </Typography>
                <br />
                <Typography variant="h6" align="center">
                  {" "}
                  If you wish to submit your request again, please scan the QR
                  code to restart the process.
                  <br />
                  若閣下希望再次遞交請求，請掃瞄二維碼以重啟流程。
                  <br />
                  <br />
                  After you click on the OK button, you will be redirected to
                  our Investor Services Centre website.
                  <br />
                  點擊「確定」按鈕後，您將被重新導向到我們的投資者服務中心網站。
                  <br />
                  <br />
                </Typography>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            {" "}
            OK 確定{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ErrorDialog;
