import React, { useEffect } from "react";
import { FormControl, FormControlLabel, Typography, FormGroup,FormHelperText } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Field } from "formik";
//import { RadioGroup } from "formik-mui";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import styles from "../styles/modules/app.module.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from '@mui/material/Checkbox';
import { useFormikContext } from "formik";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
//import RadioGroup from '@mui/material/RadioGroup';

const PreferenceForm = ({values}) => {

  PreferenceForm.title = "Step 2";

  PreferenceForm.initialValues = {
    receiveMethod: "",
    haveCopy: false,
    printLang: false,
    selectLang: ""
  };

  

  PreferenceForm.validationSchema = Yup.object().shape({
    
    // selectLang: Yup.string().when('haveCopy', {
    //   is: (value) => value === true,
    //   then: (schema) => schema.string().required(),
    //   otherwise: (schema) => schema,
    // })  
    // printLang: Yup.boolean().when('haveCopy', {
    //   is: (value) => value === true,
    //   then: (schema) => schema.oneOf([true], "Please choose the language of printed copy.").required(),
    //   otherwise: (schema) => schema,
    // })  

  });
  const {setFieldValue} = useFormikContext();

  const [PrintedCopy, setPrintedCopy] = React.useState(false)
  const [PrintedEng, setPrintedEng] = React.useState(false)
  const [PrintedChi, setPrintedChi] = React.useState(false)
  const [PrintedBoth, setPrintedBoth] = React.useState(false)
  const [PrintedLang, setPrintedLang] = React.useState("PrintedEng")
  const [Electronic, setElectronic] = React.useState(false)
  const [haveEmail, setHaveEmail] = React.useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    if(values.email)
    {
      setHaveEmail(true);
    }
    else
    {
      setHaveEmail(false);
    }
  


    if(values.email)
    {
      if(values.haveCopy)
      {
        setElectronic(false);
        setFieldValue("haveCopy", true);
        setPrintedCopy(true);
        if(values.selectLang!=="") {
          setPrintedLang(values.selectLang);
          setFieldValue("receiveMethod", values.selectLang);
        }
      }
      else
      {
        setElectronic(true);
        setPrintedCopy(false);
        setFieldValue("receiveMethod", "Email");
        setFieldValue("printLang", false);
        setFieldValue("selectLang", values.selectLang);
      }
    }
    else
    {
      setElectronic(false);
      setPrintedCopy(true);
      setFieldValue("haveCopy", true);
      setPrintedLang(values.selectLang);
      setFieldValue("receiveMethod", values.selectLang);
    }
    
    
    
  },[setFieldValue]);

  const handleCheck = (event) => {
    setPrintedCopy(event.target.checked);
    setElectronic(!event.target.checked);
    setFieldValue("haveCopy", event.target.checked);
    setFieldValue("selectLang", values.selectLang);
    setFieldValue("receiveMethod", values.selectLang);
    setPrintedLang(values.selectLang);
    if(!event.target.checked)
    {
      setPrintedEng(false);
      setPrintedChi(false);
      setPrintedBoth(false);
      setFieldValue("receiveMethod", "Email");
      // setPrintedLang(values.selectLang);
      setFieldValue("selectLang", values.selectLang);
    } 
    
    
  }

  const handleElectronic= (event) => {
    setElectronic(event.target.checked);
    setPrintedCopy(!event.target.checked);
    setFieldValue("haveCopy", !event.target.checked);
    if(event.target.checked)
    {
      setPrintedEng(false);
      setPrintedChi(false);
      setPrintedBoth(false);
      setFieldValue("receiveMethod", "Email");
      setPrintedLang(values.selectLang);
      setFieldValue("selectLang", values.selectLang);
    } 
    
  }

  const handlePrintEng = (event) => {    
    setPrintedEng(event.target.checked);
    setFieldValue("receiveMethod", event.target.checked ?"PrintedEng" : PrintedChi ? "PrintedChi" : "Email");
    setFieldValue("printLang", event.target.checked || PrintedChi ? true : !event.target.checked && !PrintedChi ? false : true);

    if(PrintedChi && event.target.checked)
    {
      setFieldValue("receiveMethod", "PrintedBoth");
    }
    
  }

  const handlePrintChi = (event) => {
    setPrintedChi(event.target.checked);
    setFieldValue("receiveMethod", event.target.checked ?"PrintedChi" : PrintedEng ? "PrintedEng" : "Email");
    setFieldValue("printLang", event.target.checked || PrintedEng ? true : !event.target.checked && !PrintedEng ? false : true);

    if(PrintedEng && event.target.checked)
    {
      setFieldValue("receiveMethod", "PrintedBoth");
    }
    
  }

  const handlePrintedLang = (event) => {
    setPrintedLang(event.target.value);   
    setFieldValue("receiveMethod", event.target.value);
    setFieldValue("selectLang", event.target.value);
  }




  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  
  return (
    <Grid container spacing={3}>
      <Container maxWidth="sm">
        <Box marginTop={5}>
          {/* <Typography className={styles.title} variant="h3">
            Future Communications
          </Typography> */}
          

          <List>
            <ListItem className={styles.listItem}>
              <ListItemText primary="Printed Copy 印刷本" primaryTypographyProps={{fontSize: '18px'}}  />
              <IOSSwitch sx={{ m: 1 }} checked={PrintedCopy}
                    onChange={handleCheck} id="haveCopy"  name="haveCopy" disabled={!haveEmail} />
            </ListItem>
          </List>
          
          {PrintedCopy && 
          <React.Fragment>
            <Typography  variant="h4">
            Please select the language version for the printed copy 
            <br/>
            請選擇印刷本的語言版本
            <br/><br/>
            </Typography>
           <FormGroup sx={{position: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems:'center'}}>
           {/* <FormControlLabel className={styles.checkboxgroup} sx={{ '& .MuiFormControlLabel-label': { fontSize: '18px' } }} control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} checked={PrintedEng} onChange={handlePrintEng} id="printLang" name="printLang"  />} label="English" />
           <FormControlLabel className={styles.checkboxgroup} sx={{ '& .MuiFormControlLabel-label': { fontSize: '18px' } }} control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} checked={PrintedChi} onChange={handlePrintChi} id="printLang" name="printLang"  />} label="Chinese" />
            */}
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={PrintedLang}
              onChange={handlePrintedLang}
            >
              <FormControlLabel value="PrintedEng" sx={{ '& .MuiFormControlLabel-label': { fontSize: '18px' } }} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="English version 英文印刷本" />
              <FormControlLabel value="PrintedChi" sx={{ '& .MuiFormControlLabel-label': { fontSize: '18px' } }} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Chinese version 中文印刷本" />
              <FormControlLabel value="PrintedBoth" sx={{ '& .MuiFormControlLabel-label': { fontSize: '18px' } }} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Both English and Chinese versions 英文及中文印刷本" />              
            </RadioGroup>
           </FormGroup>
           <Grid item xs={12}>
           <Stack sx={{ width: '100%' }} spacing={2}>
           <Alert severity="info">
           {PrintedLang==="PrintedEng" && 
            <Typography  variant="h5">
            To receive the printed English version ONLY for all future Actionable Corporate Communications and Corporate Communications;
            <br/>
            以英文印刷本收取日後的可供採取行動的公司通訊及公司通訊
            </Typography>
          }
          
          {PrintedLang==="PrintedChi" && 
            <Typography  variant="h5">
            To receive the printed Chinese version ONLY for all future Actionable Corporate Communications and Corporate Communications;
            <br/>
            以中文印刷本收取日後的可供採取行動的公司通訊及公司通訊
            </Typography>
          }

          {PrintedLang==="PrintedBoth" &&
            <Typography  variant="h5">
            To receive the printed English and Chinese versions of all future Actionable Corporate Communications and Corporate Communications;
            <br/>
            以英文及中文印刷本收取日後的可供採取行動的公司通訊及公司通訊
            </Typography>
          }
          </Alert>
          </Stack>
          </Grid>
           </React.Fragment>
          }

          <Typography  variant="h5">
            Or或<br/>
          </Typography>
          

          <List>
            <ListItem className={styles.listItem}>
              <ListItemText primary="Electronic Means 電子形式" primaryTypographyProps={{fontSize: '18px'}}  />
              <IOSSwitch sx={{ m: 1 }} checked={Electronic} disabled={!haveEmail}
                    onChange={handleElectronic}  />
            </ListItem>
          </List>


          <Grid item xs={12}>
          
          {Electronic &&
          <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="info">
            <Typography  variant="h5">
            Electronic Means in lieu of receiving printed copy and to receive email notification of the posting of the future Actionable Corporate Communications and Corporate Communications on the Company’s website to my email address
            <br/>
            以電子形式閱覽登載在  貴公司網站的所有日後的可供採取行動的公司通訊及公司通訊以代替收取印刷本，並按電郵地址收取有關可供採取行動的公司通訊及公司通訊登載在  貴公司網站的電郵通知 
            </Typography>
            </Alert>
          </Stack>
          }

          {!haveEmail &&
          <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="warning">
            <Typography  variant="h5">
              If you want to receive in Electronic Means, please input Email Address in the previous step
              <br/>
              如閣下希望以電子方式接收, 請於上一步提供電子郵件地址
           </Typography>
            </Alert>
          </Stack>
          } 
          
            
          </Grid>

        </Box>
      </Container>
    </Grid>
  );
};



export default PreferenceForm;
