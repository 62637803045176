import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import styles from "../styles/modules/app.module.scss";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../components/PageTitle";
import { format } from "date-fns";
import axios from "../api";
import { size } from "lodash";
import { Dimensions } from "react-native";
import ErrorDialog from "../components/Error";

const LandingPage = () => {
  const [disabled, setDisabled] = useState(false);
  const [expireDate, setExpireDate] = useState("");
  const [shareRegCompanyEng, setShareRegCompanyEng] = useState("");
  const [shareRegCompanyChi, setShareRegCompanyChi] = useState("");
  const [isAceInsCode, setIsAceInsCode] = useState(false);
  const [windowHeight, setwindowHeight] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const screenHeight = Dimensions.get("window").height;
  const screenWidth = Dimensions.get("window").width;
  const RPH = (percentage) => {
    return (percentage / 100) * screenHeight;
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [errorType, setErrorType] = useState("");

  const divRef = React.useRef();
  const [userHasReviewed, setUserHasReviewed] = React.useState(false);

  const scrollTracker = () => {
    const { scrollTop, scrollHeight, clientHeight } = divRef.current;
    if (scrollTop + clientHeight + 1 >= scrollHeight) {
      setUserHasReviewed(true);
    }
  };

  useEffect(() => {
    axios
      .get(`/profile`)
      .then((response) => {
        var date = format(new Date(response.data.expiryDate), "dd/MM/yyyy");

        setExpireDate(date);

        var shareRegEng = response.data.shareRegEng;
        var shareRegChi = response.data.shareRegChi;
        var insCode = response.data.instrumentCode;

        console.log("insCode:" + insCode);

        if (insCode.length === 5) {
          var subInsCode = insCode.toString().substring(0, 2);

          if (subInsCode === "08") {
            setIsAceInsCode(true);
          }
        } else if (insCode.length === 4) {
          var subInsCode = insCode.toString().substring(0, 1);

          if (subInsCode === "8") {
            setIsAceInsCode(true);
          }
        } else {
          setIsAceInsCode(false);
        }

        if (shareRegEng !== "" && shareRegEng !== null) {
          setShareRegCompanyEng(shareRegEng);
        }

        if (shareRegChi !== "" && shareRegChi !== null) {
          setShareRegCompanyChi(shareRegChi);
        }
      })
      .catch((error) => {
        setErrorType("unknown");
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          setErrorMessage(error.response.data);
        } else if (error.request) {
          console.log(error.request);
          setErrorMessage(error.request);
        } else {
          console.log("Error", error.message);
          setErrorMessage(error.message);
        }
        console.log(error.config);
        setErrorMessage(error.message);
      });
  }, []);

  return (
    <>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box>
          <PageTitle />
          <div>
            <Typography variant="h4" align="left">
              Before you complete this Online Form, you must have read,
              understood and agreed to the following Notes and Personal
              Information Collection Statement:
              <br />
            </Typography>
            <Typography variant="h4" align="left">
              在你完成這張網上表格前,閣下必須已閱讀、理解並同意以下附註及收集個人資料聲明：
              <br />
            </Typography>
          </div>
          <div>
            <div 
              className={styles.text}
              ref={divRef}
              onScroll={() => scrollTracker()}
            >
              <Typography variant="h5">
                Notes and Personal Information Collection Statement:
              </Typography>
              <p>
                1. This Online Form is prepared by {shareRegCompanyEng}, a
                member of Tricor Group, who acts as the agent on behalf of the
                Company. The corporate website of Tricor Group is at
                https://www.tricorglobal.com/.
              </p>
              <p>
                {" "}
                <br />
                2.Corporate Communications include any document(s) issued or to
                be issued by the Company for the information or action of
                holders of any of its securities or the investing public,
                including but not limited to (a) the directors’ report and its
                annual accounts together with a copy of the auditors’ report
                and, where applicable, its summary financial report; (b) the
                interim report and, where applicable, its summary interim
                report; (c) the quarterly report, if any; (d) a notice of
                meeting; (e) a listing document; (f) a circular; and (g) a proxy
                form.
              </p>
              <p>
                <br />
                3. Actionable Corporate Communications refer to any Corporate
                Communications that seek instructions from the securities
                holders of the Company on how they wish to exercise their rights
                or make elections as the Company’s securities holders.
              </p>

              <p>
                {" "}
                <br />
                4. Any Shareholder may request for a printed copy of the
                Corporate Communications in the Shareholder’s choice of language
                version. In case of both English and Chinese versions of the
                Corporate Communications are printed as one combined document, a
                printed copy of the Corporate Communications in both English and
                Chinese versions will be sent to the Shareholder, regardless of
                the Shareholder’s choice of language version.
              </p>
              <p>
                {" "}
                <br />
                5. It is the Shareholder’s responsibility to provide an email
                address that is functional. If the Company does not receive the
                completed Online Form, or the email address provided is not
                functional, the Company will send any Actionable Corporate
                Communication in printed form to the Shareholder’s registered
                address together with a request for soliciting a functional
                email address from such Shareholder to facilitate electronic
                dissemination of Actionable Corporate Communications in the
                future. The Company will be considered to have complied with
                {isAceInsCode
                  ? " the Rules Governing the Listing of Securities on the GEM of the Stock Exchange of Hong Kong Limited "
                  : " the Rules Governing the Listing of Securities on the Stock Exchange of Hong Kong Limited "}
                if it sends Actionable Corporate Communications to the email
                address provided by a Shareholder without receiving any
                “non-delivery message”.
                {/* <br />
                4. It is the Shareholder’s responsibility to provide an email
                address that is functional. If the Company does not receive the
                (fully) completed Online Form, or the email address provided is
                not functional, the Company will send any Actionable Corporate
                Communication in printed form to the Shareholder’s known address
                together with a request for soliciting your functional email
                address to facilitate electronic dissemination of Actionable
                Corporate Communications in the future. The Company will be
                considered to have complied with the Rules Governing the Listing
                of Securities on the Stock Exchange of Hong Kong Limited
                if it sends Actionable Corporate Communications to the email
                address provided by a Shareholder without receiving any
                “non-delivery message”. */}
              </p>
              <p>
                {" "}
                <br />
                6. In the case of joint registered holders, this Online Form
                shall be deemed to be submitted by the joint registered holder
                whose name first appears on the register of members, for and on
                behalf of all the joint registered holders.
                <br />
              </p>
              <p>
                <br />
                7. This request will be valid unless it is revoked or
                superseded. Any request to receive printed copy of Corporate
                Communications and Actionable Corporate Communications will
                automatically expire after a fixed period of time. For details,
                please refer to the arrangement published on the Company’s
                website. Any Shareholder who wishes to continue receiving a
                printed copy of future Corporate Communications and Actionable
                Corporate Communications must submit further request in writing.
              </p>
              <br />
              <Typography variant="h6">
                PERSONAL INFORMATION COLLECTION STATEMENT
              </Typography>
              <p>
                {" "}
                <br />
                “Personal Data” in this statement has the same meaning as
                “personal data” defined in the Personal Data (Privacy)
                Ordinance, Cap. 486 (the “PDPO”), which may include but is not
                limited to your name, contact telephone number, email address
                and mailing address. Your supply of Personal Data is on a
                voluntary basis for the purposes of verifying and recording your
                means of receipt, choice of language and/or delivery of
                Corporate Communications in the manner chosen (the “Purpose”).
                In case of your failure to provide correct information, the
                Company and/or its agent(s) may not be able to process your
                requests. Your Personal Data may be disclosed or transferred by
                the Company and its agent(s) to their respective affiliates,
                subsidiaries, or third party service provider in connection with
                the Purposes, and/or to such parties who are authorised by law
                to request the information or are otherwise relevant for the
                Purposes and need to receive the information. Your Personal Data
                will be retained for such period as may be necessary for
                fulfilling the Purposes or such longer period as may be required
                by applicable laws. You have the right to request access to
                and/or to correct your Personal Data in accordance with the
                provisions of the PDPO. Any such request for access to and/or
                correction of any of your Personal Data should be in writing to
                either the Company directly, or to us through any of the
                following means:
                <List>
                  <ListItem>
                    <ListItemText primary="By mail to:" />
                    <Typography variant="subtitle1">
                      {shareRegCompanyEng}
                      <br />
                      Data Privacy Officer
                      <br />
                      17/F, Far East Finance Centre
                      <br />
                      16 Harcourt Road, Hong Kong
                      <br />
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="By email to:" />
                    <Typography variant="subtitle1">
                      is-enquiries@hk.tricorglobal.com
                    </Typography>
                  </ListItem>
                </List>
                <br />
                For more information in respect of Tricor Group’s data
                processing practices, please review our Privacy Policy at
                https://www.tricorglobal.com/privacy-policy/. If you have any
                queries or would like to exercise any of your rights in relation
                to your personal data or make a complaint, please contact us at
                privacy@hk.tricorglobal.com.
              </p>
              <br />
              <Typography variant="h5">附註及收集個人資料聲明</Typography>
              <p>
                1. 此網上表格由
                {shareRegCompanyChi}
                ，卓佳集團之成員，作為公司之代理人向股東發出。卓佳集團的公司網站為https://www.tricorglobal.com/。
              </p>
              <p>
                {" "}
                <br />
                2.
                企業通訊包括本公司發佈或將予發佈以供其任何證券持有人或投資大眾參照或採取行動的任何文件，其中包括但不限於
                (a) 董事會報告，
                公司年度帳目連同核數師報告以及（如適用）財務摘要報告； (b)
                中期報告及（如適用）中期摘要報告； (c) 季度報告（如有）； (d)
                會議通告； (e) 上市文件； (f) 通函； (g) 代表委任表格。
              </p>
              <p>
                {" "}
                <br />
                3.可採取行動的企業通訊是指向公司證券持有人尋求指示，說明他們希望如何行使其權利或選擇作為公司證券持有人的任何企業通訊。
              </p>
              <p>
                {" "}
                <br />
                4.任何股東均可透過網上表格中選定的通訊方式索取其企業通訊之語言版本的印刷版。若企業通訊的英文版和中文版為合併文件印刷，則無論股東選擇何種語言版本，企業通訊的英文版和中文版的印刷本都將發送給股東。
              </p>
              <p>
                {" "}
                <br />
                5.
                股東有責任提供有效的電子郵箱地址。如果本公司沒有收到填寫完整的網上表格或提供的電子郵箱地址無效，
                本公司將以印刷本形式發送可供採取行動的企業通訊，連同一份索取股東有效電子郵箱地址的表格，以便將來以電子通訊方式發送可供採取行動的企業通訊。
                如果本公司向股東提供的電子郵箱地址發送可供採取行動的企業通訊而未收到任何“未送達信息”，則本公司將被視為已遵守香港聯合交易所有限公司
                {isAceInsCode ? "創業板證券上市規則" : "證券上市規則"}。
              </p>
              <p>
                {" "}
                <br />
                6.
                倘若干人士聯名持有股份，本網上表格應被視為由於股東名冊內排名首位姓名的聯名登記股東代表所有聯名登記股東提交。
              </p>
              <p>
                {" "}
                <br />
                7.
                此請求將一直有效，除非被撤銷或取代。任何接收企業通訊及可供採取行動的企業通訊印刷版之請求將於固定時段後自動到期。詳情請參閱公司網站。
                如果股東希望繼續收到日後的企業通訊和可供採取行動的企業通訊的印刷本，則需要提交進一步書面請求。
                <br />
              </p>
              <br />
              <Typography variant="h6">收集個人資料聲明</Typography>
              <p>
                {" "}
                <br />
                本聲明中的「個人資料」與《個人資料（私隱）條例》（香港法例）所界定的「個人資料」具有相同涵義。《個人資料（私隱）條例》第486章,
                其中可能包括但不限於您的姓名、聯絡電話號碼、電子郵件地址和郵寄地址。您自願提供個人資料，目的是為了驗證和記錄您的接收方式、語言選擇和/或以所選方式交付接收公司通訊（「目的」）。如果您未能提供足夠或正確的信息，本公司和/或其代理人可能無法處理您在此網上表格中所述的指示和/或請求。您的個人資料可能由本公司及其代理人透露或轉移給其各自的聯屬公司、子公司、位於香港的轉讓辦事處或與目的相關的第三方服務提供者和/或其他公司或機構為實現任何既定或預期目的，向經法律授權請求該資訊的各方或與該目的相關並需要接收該資訊的各方提供資訊。您的個人資料將保留一段時間，以驗證和記錄實現目的，或根據適用法律要求更長的時間。您有權根據《個人資料保護條例》的規定要求查閱及/或更正您的個人資料。任何此類存取和/或更正您的個人資料的請求應直接以書面形式向本公司提出，或透過以下任何方式向卓佳其代理人提出：
                <List>
                  <ListItem>
                    <ListItemText primary="經郵寄:" />
                    <Typography variant="subtitle1">
                      {shareRegCompanyChi}
                      <br />
                      個人資料私隱主任
                      <br />
                      香港夏愨道16號
                      <br />
                      遠東金融中心17樓
                      <br />
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="經電郵:" />
                    <Typography variant="subtitle1">
                      is-enquiries@hk.tricorglobal.com
                    </Typography>
                  </ListItem>
                </List>
                <br />
                有關卓佳集團資料處理信息，請參閱我們的隱私權政策：https://www.tricorglobal.com/privacy-policy/。如有任何疑問或欲對任何個人資料相關事宜作出投訴，請透過privacy@hk.tricorglobal.com與我們聯繫。
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox disabled={!userHasReviewed} />}
                  label={
                    <div>
                      <Typography variant="caption" sx={{ fontSize: "12px" }}>
                        I have read, understood and agreed with the terms and
                        conditions above.
                      </Typography>
                      <br />
                      <Typography variant="caption" sx={{ fontSize: "12px" }}>
                        本人已經詳閱、明白及同意上述各規條。
                      </Typography>
                    </div>
                  }
                  onChange={(e) => setDisabled(e.target.checked)}
                ></FormControlLabel>
              </FormGroup>
            </div>
            <br />
            <Box textAlign="center">
              <Link
                to="/update"
                style={!disabled ? { pointerEvents: "none" } : null}
              >
                <Button
                  disabled={!disabled}
                  color="primary"
                  variant="contained"
                >
                  Start 開始
                </Button>
              </Link>
            </Box>
          </div>
        </Box>
        {errorMessage ? (
          <ErrorDialog errorMessage={errorMessage} type={errorType} />
        ) : (
          <>
            
          </>
        )}
      </Container>
    </>
  );
};

export default LandingPage;
