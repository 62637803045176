import React from "react";
import Typography from "@mui/material/Typography";

function PageTitle() {
  return (
    <div>
      <Typography variant="h3" align="left">
        Online Form 網上表格
        <br />
        <br />
      </Typography>
      <Typography variant="h4" align="left">
        {" "}
        For Provision of Electronic Contact Details for the Receipt of all
        Future Actionable Corporate Communications and/or the Choice of Language
        and Means of Receipt of all Future Corporate Communications of the
        Company
        <br />
      </Typography>{" "}
      <Typography variant="h4" align="left">
        {" "}
        提供電子聯絡資料以收取本公司日後的可供採取行動的企業通訊及/或選擇收取日後的企業通訊之語言及方式之網上表格
        <br />
        <br />
      </Typography>
    </div>
  );
}

export default PageTitle;
