import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import tick from "../tick.jpg";
import Box from "@mui/material/Box";
function SuccessDialog(props) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    window.location = "https://www.tricoris.com/";
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle id="success-dialog"> </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box sx={{ textAlign: "center" }}>
            <img
              color="white"
              height={80}
              width={80}
              src={tick}
              alt="Successful"
            />
          </Box>
          <Typography variant="h4" align="center" fontWeight={"bold"}>
            {" "}
            Thank You
            <br />
            Your information has been submitted successfully. <br />
            感謝
            <br />
            您的資料已成功提交。
          </Typography>
          <br />
          <Typography variant="h6" align="center">
            {" "}
            After you click on the OK button, you will be redirected to our
            Investor Services Centre website.
            <br />
            點擊「確定」按鈕後，您將被重新導向到我們的投資者服務中心網站。
            <br />
            <br />
            Reference Number 參考編號 : {props?.submissionRefNum}.
            <br />
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          {" "}
          OK 確定{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SuccessDialog;
