import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../tricor-logo-grey.png";
import { styled } from '@mui/material/styles';

const drawerWidth = 240;
const navItems = ["Home", "Contact"];

function AppHeader(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  React.useEffect(() => {
    document.title = "e-Intructions - Tricor Investor Services Centre 電子指示 - 卓佳投資者服務中心"
 }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src={logo} alt="Logo" />
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        <img src={logo} alt="Logo" />;
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

    const StyledToolbar = styled(Toolbar)(({ theme }) => ({
      alignItems: 'flex-start',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      // Override media queries injected by theme.mixins.toolbar
      '@media all': {
        minHeight: 100,
      },
    }));

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar sx={{ bgcolor: "#FFFFFF" }} component="nav">
          <StyledToolbar>
            <IconButton
              color="dark"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              {/* <MenuIcon /> */}
              <img src={logo} alt="Logo" style={{height:48, width:309}}/>;
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              <img src={logo} alt="Logo" style={{height:48, width:309}} />;
            </Typography>
            {/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) => (
                <Button key={item} sx={{ color: "#040B19" }}>
                  {item}
                </Button>
              ))}
            </Box> */}
          </StyledToolbar>
        </AppBar>

        <nav>
          {/* <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          > */}
          {drawer}
          {/* </Drawer> */}
        </nav>
      </Box>
    </>
  );
}

AppHeader.propTypes = {
  window: PropTypes.func,
};

export default AppHeader;
