import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook } from "@mui/icons-material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box } from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import zIndex from "@mui/material/styles/zIndex";

export default function AppFooter() {
  return (
    <Box
      component="footer"
      
      sx={{
        backgroundColor: "#040b19",
        p: 2,
        //position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex:1,
        flex: '0 0 80px',
        margintop: 'auto'
        // height:'auto',
        // marginTop:'100vh',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="#FFFFFF" gutterBottom>
              Contact us: 
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
              Hotline: (852) 2980 1333 (9:00 a.m. to 6:00 p.m., Monday to Friday (excluding public holidays))
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
              email: is-enquiries@hk.tricorglobal.com
            </Typography>            
            <br />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="#FFFFFF" gutterBottom>
              聯絡我們:
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
              熱線: (852) 2980 1333 (於星期一至星期五（公眾假期除外）上午9時至下午6時正提供熱線服務)
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
              電郵: is-enquiries@hk.tricorglobal.com
            </Typography>            
            <br />
          </Grid>

          {/* <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="#FFFFFF" gutterBottom>
              Follow Us
            </Typography>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/tricorgroup"
              color="#FFFFFF"
            >
              <LinkedInIcon />
            </Link>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/tricorglobal/"
              color="#FFFFFF"
              sx={{ pl: 1, pr: 1 }}
            >
              <Facebook />
            </Link>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/channel/UCZMfPlqndgkLbYTM63Visxg"
              color="#FFFFFF"
            >
              <YouTubeIcon />
            </Link>
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="#FFFFFF" gutterBottom>
              Investor Services
            </Typography>
            <Box mt={1}>
            <Typography variant="subtitle1" color="#FFFFFF">
              {"Copyright © "} {new Date().getFullYear()}{" "}
              <Link color="inherit" href="https://www.tricorglobal.com/">
                {"Tricor Group. All Rights Reserved."}
              </Link>
            </Typography>
          </Box>
          </Grid>
          
        
        
        </Grid>
      </Container>
    </Box>
  );
}
