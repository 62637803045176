import React, { useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import styles from "../styles/modules/app.module.scss";
import axios from "../api";

const PreviewSubmit = ({ values }) => {
  const [userProfileData, setUserProfileData] = React.useState([]);
  const [eNameData, setENameData] = React.useState("");
  const [cNameData, setCNameData] = React.useState("");
  const [eCompanyNameData, setECompanyNameData] = React.useState("");
  const [cCompanyNameData, setCCompanyNameData] = React.useState("");
  const [eAddrData, setEAddrData] = React.useState("");
  const [cAddrData, setCAddrData] = React.useState("");

  const [receiveMethod, setReceiveMethod] = React.useState("");

  useEffect(() => {
    window.scrollTo(0, 0)
    axios
      .get(`/profile`)
      .then((response) => {
        setUserProfileData(response.data);

        var eName1 = response.data.shhrEName1;
        var eName2 = response.data.shhrEName2;
        var eName3 = response.data.shhrEName3;
        var eName4 = response.data.shhrEName4;

        var displayEName = "";

        if (eName1 !== "" && eName1 !== null) {
          displayEName += eName1;
        }
        if (eName2 !== "" && eName2 !== null) {
          displayEName += ", " + eName2;
        }
        if (eName3 !== "" && eName3 !== null) {
          displayEName += ", " + eName3;
        }
        if (eName4 !== "" && eName4 !== null) {
          displayEName += ", " + eName4;
        }

        setENameData(displayEName);

        var cName1 = response.data.shhrCName1;
        var cName2 = response.data.shhrCName2;
        var cName3 = response.data.shhrCName3;
        var cName4 = response.data.shhrCName4;

        var displayCName = "";

        if (cName1 !== "" && cName1 !== null) {
          displayCName += cName1;
        }
        if (cName2 !== "" && cName1 !== null) {
          displayCName += ", " + cName2;
        }
        if (cName3 !== "" && cName1 !== null) {
          displayCName += ", " + cName3;
        }
        if (cName4 !== "" && cName1 !== null) {
          displayCName += ", " + cName4;
        }

        setCNameData(displayCName);

        if (values.receiveMethod !== "") {
          var receiveMethodText = "";
          if (values.receiveMethod === "PrintedEng") {
            receiveMethodText = "Printed English version ONLY 英文印刷本";
          } else if (values.receiveMethod === "PrintedChi") {
            receiveMethodText = "Printed Chinese version ONLY 中文印刷本";
          } else if (values.receiveMethod === "PrintedBoth") {
            receiveMethodText = "Printed both English and Chinese version ONLY 英文及中文印刷本";
          } else if (values.receiveMethod === "Email") {
            receiveMethodText = "Email 電子郵件";
          }

          setReceiveMethod(receiveMethodText);
        }

        var displayCompanyName = "";
        var cCompanyName1 = response.data.inCorpC1;
        var cCompanyName2 = response.data.inCorpC2;
        var cCompanyName3 = response.data.inCorpC3;

        if (cCompanyName1 !== "" && cCompanyName1 !== null) {
          displayCompanyName += " " + cCompanyName1;
        }
        if (cCompanyName2 !== "" && cCompanyName2 !== null) {
          displayCompanyName += ", " + cCompanyName2;
        }
        if (cCompanyName3 !== "" && cCompanyName3 !== null) {
          displayCompanyName += ", " + cCompanyName3;
        }

        setCCompanyNameData(response.data.companyCName + displayCompanyName + `(「本公司」)`);

        var displayECompanyName = "";
        var eEompanyName1 = response.data.inCorpE1;
        var eEompanyName2 = response.data.inCorpE2;
        var eEompanyName3 = response.data.inCorpE3;

        if (eEompanyName1 !== "" && eEompanyName1 !== null) {
          displayECompanyName += " " + eEompanyName1;
        }
        if (eEompanyName2 !== "" && eEompanyName2 !== null) {
          displayECompanyName += " " + eEompanyName2;
        }
        if (eEompanyName3 !== "" && eEompanyName3 !== null) {
          displayECompanyName += " " + eEompanyName3;
        }

        setECompanyNameData(response.data.companyEName + displayECompanyName + `("the Company")`);

        var displayEAddr = "";

        var eAddr1 = response.data.eAddr1;
        var eAddr2 = response.data.eAddr2;
        var eAddr3 = response.data.eAddr3;
        var eAddr4 = response.data.eAddr4;

        if (eAddr1 !== "" && eAddr1 !== null) {
          displayEAddr += eAddr1;
        }
        if (eAddr2 !== "" && eAddr2 !== null) {
          displayEAddr += " " + eAddr2;
        }
        if (eAddr3 !== "" && eAddr3 !== null) {
          displayEAddr += " " + eAddr3;
        }
        if (eAddr4 !== "" && eAddr4 !== null) {
          displayEAddr += " " + eAddr4;
        }

        setEAddrData(displayEAddr);


        var displayCAddr = "";

        var cAddr1 = response.data.cAddr1;
        var cAddr2 = response.data.cAddr2;
        var cAddr3 = response.data.cAddr3;
        var cAddr4 = response.data.cAddr4;

        if (cAddr1 !== "" && cAddr1 !== null) {
          displayCAddr += cAddr1;
        }
        if (cAddr2 !== "" && cAddr2 !== null) {
          displayCAddr += " " + cAddr2;
        }
        if (cAddr3 !== "" && cAddr3 !== null) {
          displayCAddr += " " + cAddr3;
        }
        if (cAddr4 !== "" && cAddr4 !== null) {
          displayCAddr += " " + cAddr4;
        }

        setCAddrData(displayCAddr);

      })
      .catch((error) => {
        console.log(error);
        window.location = "/";
      });
  }, []);

  return (
    <>
      <List>
        <ListItem className={styles.listItem}>
          <ListItemText className={styles.listItemText} primary={<div><Typography >Instrument Code<br/> 股份代號</Typography></div>} />
          <Typography variant="subtitle1" className={styles.total}>
            {userProfileData?.instrumentCode}
          </Typography>
        </ListItem>

        <ListItem className={styles.listItem}>
          <ListItemText className={styles.listItemText} primary={<div><Typography>Company Name (in English)<br/> 公司名稱 (英文)</Typography></div>} />
          <Typography variant="subtitle1" className={styles.total}>
            {eCompanyNameData}
          </Typography>
        </ListItem>

        <ListItem className={styles.listItem}>
          <ListItemText className={styles.listItemTextChi} primary={<div><Typography>Company Name (in Chinese)<br/> 公司名稱 (中文)</Typography></div>} />
          <Typography variant="subtitle1" className={styles.total}>
            {cCompanyNameData}
          </Typography>
        </ListItem>

        <ListItem className={styles.listItem}>
          <ListItemText className={styles.listItemText} primary={<div><Typography>Name(s)<br/> 姓名 </Typography></div>} />
          <Typography variant="subtitle1" className={styles.total}>
            {eNameData}<br/>{cNameData}
          </Typography>
        </ListItem>

        {/* <ListItem className={styles.listItem}>
          <ListItemText className={styles.listItemTextChi} primary={<div><Typography>Name(s) (in Chinese)<br/> 姓名 (中文)</Typography></div>} />
          <Typography variant="subtitle1" className={styles.total}>
            {cNameData}
          </Typography>
        </ListItem> */}

        <ListItem className={styles.listItem}>
          <ListItemText className={styles.listItemText} primary={<div><Typography>Address <br/> 地址 </Typography></div>} />
          <Typography variant="subtitle1" className={styles.total}>
            {eAddrData}<br/>{cAddrData}
          </Typography>
        </ListItem>

        {/* <ListItem className={styles.listItem}>
          <ListItemText className={styles.listItemTextChi} primary={<div><Typography>Address (in Chinese)<br/> 地址 (中文)</Typography></div>} />
          <Typography variant="subtitle1" className={styles.total}>
            {cAddrData}
          </Typography>
        </ListItem> */}

        <ListItem className={styles.listItem}>
          <ListItemText className={styles.listItemText} primary={<div><Typography>Email Address<br/> 電子郵件地址</Typography></div>} />
          <Typography variant="subtitle1" className={styles.total}>
            {values.email}{!values.email && <>Not provided 沒有提供</>}
          </Typography>
        </ListItem>

        <ListItem className={styles.listItem}>
          <ListItemText className={styles.listItemText} primary={<div><Typography>Email Address (Re-type)<br/> 電子郵件地址 (再次輸入)</Typography></div>} />
          <Typography variant="subtitle1" className={styles.total}>
            {values.email2}{!values.email2 && <>Not provided 沒有提供</>}
          </Typography>
        </ListItem>

        <ListItem className={styles.listItem}>
          <ListItemText className={styles.listItemText} primary={<div><Typography>Contact Telephone Number<br/> 聯絡電話號碼</Typography></div>} />
          <Typography variant="subtitle1" className={styles.total}>
            {values.phoneNumber}{!values.phoneNumber && <>Not provided 沒有提供</>}
          </Typography>
        </ListItem>

        <ListItem className={styles.listItem}>
          <ListItemText className={styles.listItemTextChi} primary={<div><Typography>Receive Method<br/> 收取方式</Typography></div>} />
          <Typography variant="subtitle1" className={styles.total}>
            {receiveMethod}
          </Typography>
        </ListItem>
      </List>
    </>
  );
};

PreviewSubmit.title = "Step 3";
PreviewSubmit.initialValues = {};
PreviewSubmit.validationSchema = Yup.object({});

export default PreviewSubmit;
