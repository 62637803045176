import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios, { setToken } from "../api";
import ErrorDialog from "../components/Error";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import styles from "../styles/modules/app.module.scss";

const Home = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const { token } = useParams();

  useEffect(() => {
    if(token === undefined || token === null){ setErrorMessage("Missing QRCode"); }
    else(
    axios
      .get(`/profile/${token}`, {})
      .then((response) => {
        setToken(`${response.data.jwtToken}`);

        window.location = "/profiles/";
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          setErrorMessage(error.response.data);
        } else if (error.request) {
          console.log(error.request);
          setErrorMessage(error.request);
        } else {
          console.log("Error", error.message);
          setErrorMessage(error.message);
        }
        console.log(error.config);
      }));
  }, [token]);

  return (
    <>
    <Box className={styles.boxHome }>
      <div>
        {errorMessage ? (
          <ErrorDialog errorMessage={errorMessage} type={"token"} />
        ) : (
          <>
        
          </>
        )}
      </div>
    </Box>
    </>
  );
};

export default Home;
